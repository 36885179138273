/*------------------------------------
  DatePicker
------------------------------------*/

.u-datepicker {
    position: relative;
    display: flex;

    .flatpickr-calendar {
        top: 3.75rem !important;
        left: 0 !important;
        box-shadow: $box-shadow;

        &::before,
        &::after {
            display: none;
        }
    }

    .flatpickr-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        flex-grow: 1;
    }

    .flatpickr-months {
        position: relative;
        background-color: $primary;
        @include border-top-radius($border-radius);
        padding: 0.75rem;

        .cur-month,
        .cur-year {
            color: $white;
        }
    }

    .flatpickr-month,
    .flatpickr-monthDropdown-months {
        background-color: $primary;
        font-weight: 600;
    }

    .flatpickr-current-month,
    .flatpickr-prev-month,
    .flatpickr-next-month {
        padding: 0;
        line-height: 2;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
        top: 50%;
        transform: translate(0, -50%);

        &.flatpickr-prev-month {
            left: 0.75rem;
        }

        &.flatpickr-next-month {
            right: 0.75rem;
        }

        svg {
            fill: $white-color-70;
        }

        &:hover {
            svg {
                fill: $white;
            }
        }
    }

    .flatpickr-weekdays {
        background-color: $white;
    }

    .flatpickr-weekday {
        color: $dark;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        background-color: $white;
    }

    .flatpickr-day {
        color: $dark;
        border-color: transparent;

        &:focus,
        &:hover {
            color: $primary;
            background-color: rgba($primary, 0.1);
        }

        &.inRange {
            background-color: $gray-300;
            box-shadow: -0.3125rem 0 0 $gray-300, 0.3125rem 0 0 $gray-300;
        }

        &.today {
            background-color: $white;
            border-color: #bbb;
            &:hover {
                background-color: #bbb;
                color: $white;
            }
        }

        &.prevMonthDay,
        &.nextMonthDay {
            color: $gray-300;

            &:focus,
            &:hover {
                color: $gray-500;
                background-color: $gray-300;
            }
        }

        &.disabled {
            color: $gray-300;

            &:hover {
                color: $gray-500;
                background-color: $gray-300;
            }
        }

        &.selected {
            color: $white;
            background-color: $primary;
            border-color: $primary;
            &:focus,
            &:hover {
                &.prevMonthDay,
                &.nextMonthDay {
                    color: $primary;
                    background-color: transparent;
                }
            }

            &.startRange,
            &.endRange {
                color: $white;
                background-color: $primary;
            }
        }
    }

    .numInputWrapper {
        span.arrowUp,
        span.arrowDown {
            // display: none;
        }

        &:hover {
            background-color: transparent;
        }
    }
}

/* Positions */
.u-datepicker {
    &--top {
        .flatpickr-calendar {
            top: auto !important;
            bottom: 3.5rem !important;
        }
    }
}

.flatpickr-calendar {
    box-shadow: $box-shadow;
    &::before,
    &::after {
        display: none;
    }
}

.input-corners {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex-grow: 1;
}

.flatpickr-months {
    position: relative;
    background-color: $primary;
    @include border-top-radius($border-radius);
    padding: 0.75rem;

    .cur-month,
    .cur-year {
        color: $white;
    }
}

.flatpickr-month,
.flatpickr-monthDropdown-months {
    background-color: $primary;
    font-weight: 600;
}

.flatpickr-current-month,
.flatpickr-prev-month,
.flatpickr-next-month {
    padding: 0;
    line-height: 2;
}

.flatpickr-prev-month,
.flatpickr-next-month {
    // top: 50%;
    // transform: translate(0, -50%);

    &.flatpickr-prev-month {
        left: 0.75rem;
    }

    &.flatpickr-next-month {
        right: 0.75rem;
    }

    svg {
        fill: $white-color-70;
    }

    &:hover {
        svg {
            fill: $white;
        }
    }
}
.flatpickr-weekdaycontainer {
    background-color: $white;
}

.flatpickr-weekdays {
    background-color: $white;
}

.flatpickr-weekday {
    color: $dark;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    background-color: $white;
    background: $white;
}

.flatpickr-weekday {
    color: $dark;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    background-color: $white;
    background: $white;
}

.flatpickr-day {
    color: $dark;
    border-color: transparent;

    &:focus,
    &:hover {
        color: $primary;
        background-color: rgba($primary, 0.1);
    }

    &.inRange {
        background-color: $gray-300;
        box-shadow: -0.3125rem 0 0 $gray-300, 0.3125rem 0 0 $gray-300;
    }

    &.today {
        background-color: $white;
        border-color: #bbb;
        &:hover {
            background-color: #bbb;
            color: $white;
        }
    }

    &.prevMonthDay,
    &.nextMonthDay {
        color: $gray-300;

        &:focus,
        &:hover {
            color: $gray-500;
            background-color: $gray-300;
        }
    }

    &.disabled {
        color: $gray-300;

        &:hover {
            color: $gray-500;
            background-color: $gray-300;
        }
    }

    &.selected {
        color: $white;
        background-color: $primary;
        border-color: $primary;
        &:focus,
        &:hover {
            &.prevMonthDay,
            &.nextMonthDay {
                color: $primary;
                background-color: transparent;
            }
        }

        &.startRange,
        &.endRange {
            color: $white;
            background-color: $primary;
        }
    }
}

.numInputWrapper {
    span.arrowUp,
    span.arrowDown {
        // display: none;
    }

    &:hover {
        background-color: transparent;
    }
}

span.flatpickr-weekday {
    background-color: $white;
}

.div.flatpickr-month {
    background-color: $primary;
}

.flatpickr-months .flatpickr-month {
    background-color: $primary;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background-color: $primary;
    font-weight: 600;
}

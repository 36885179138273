.card-footer {
    &--settings {
        width: 100%;
        bottom: 0;
    }
}

.actionsContainer {
    display: flex;
    justify-content: flex-end;

    .cancelButton {
        margin-right: 8px;
        background-color: $white !important;
        border: none;
        color: $red;
    }
}

.signingConsentModal {
    .modal-header {
        justify-content: center;
        padding: 30px 20px 20px;
        border-bottom: none;

        .modal-title {
            font-size: 16px;
            font-weight: 600;
            color: $jblack;
        }
    }

    .modal-body {
        padding: 0 30px;
        text-align: center;
        font-size: 14px;
    }

    .modal-footer {
        padding: 20px 25px 40px;
        border-top: none;
        justify-content: space-around;

        button {
            width: 140px;
            height: 50px;
            font-size: 14px;
            font-weight: 600;

            padding: 6px 16px;
            margin: 0;
            border: none;
        }

        button:first-child {
            background-color: $red !important;
        }
    }
}

.inputPointer {
    height: 40px;
    width: 100px;
    border-radius: 5px 0 0 5px;
    color: white;
    text-align: center;
    line-height: 40px;
    font-weight: 600;
    background-color: $primary;
    position: absolute;
    left: -90px;
    transition: top 0.2s;
    z-index: 10;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        right: -40px;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent; /* 40px height (20+20) */
        border-left: 40px solid $primary;
    }
}
